import React, { useEffect, useState } from "react";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Typography,
  Avatar,
  IconButton,
  Badge,
} from "@mui/material";
import {
  BadgeSharp,
  ClassOutlined,
  ClassSharp,
  Countertops,
  CurrencyRupee,
  Home,
  Web,
} from "@mui/icons-material";
import { useSchoolContext } from "src/contexts/school-context";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate } from "react-router";
import { paths } from "src/paths";
import { useAuthContext } from "src/contexts/auth-context";
import NotificationsIcon from "@mui/icons-material/Notifications";

export default function BottomNav() {
  const navigate = useNavigate();
  const {
    gradesSectionsList,
    gradeIndexToLabel,
    parentSelectedStudent,
    changeParentStudent,
    notifications,
  } = useSchoolContext();

  const { user } = useAuthContext();
  const [selectedNavItem, setSelectedNavItem] = useState(0);
  const [openStudentDrawer, setOpenStudentDrawer] = useState(false);
  const [openAttendanceDrawer, setOpenAttendanceDrawer] = useState(false);
  const [bottomNavigationItems, setBottomNavigationItems] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    const lastCheckedTime = new Date(user?.settings?.last_checked_notification_at);
    const newNotifications = notifications?.filter((notification) => {
      const notificationTime = new Date(notification?.created_at);
      return notificationTime > lastCheckedTime;
    });
    setUnreadNotificationsCount(newNotifications?.length);
  }, [notifications, user]);

  useEffect(() => {
    let tempBottomNavigationItems = [];
    if (user.role === "admin" || user.role === "manager") {
      tempBottomNavigationItems = [
        ...tempBottomNavigationItems,
        {
          onClick: () => navigate(paths.classes),
          icon: <ClassOutlined sx={{ fontSize: 18 }} />,
          label: "Classes",
        },
        {
          onClick: () => navigate(paths.teachers),
          icon: <BadgeSharp sx={{ fontSize: 18 }} />,
          label: "Teachers",
        },
        {
          icon: <Home sx={{ fontSize: 18 }} />,
          onClick: () => navigate(paths.homeMenu),
          label: "Home",
        },
        {
          onClick: () => navigate(paths.finance),
          icon: <CurrencyRupee sx={{ fontSize: 18 }} />,
          label: "Finance",
        },
        {
          onClick: () => navigate(paths.attendance),
          icon: <Countertops sx={{ fontSize: 18 }} />,
          label: "Attendance",
        },
      ];
      setSelectedNavItem(2);
    } else if (user.role === "teacher") {
      tempBottomNavigationItems = [
        ...tempBottomNavigationItems,
        {
          label: "Dashboard",
          icon: <Web sx={{ fontSize: 18 }} />,
          onClick: () => {
            setOpenAttendanceDrawer(false);
            navigate(paths.dashboard);
          },
        },
        {
          label: "Update Result",
          icon: <BorderColorIcon sx={{ fontSize: 18 }} />,
          onClick: () => {
            setOpenAttendanceDrawer(false);
            navigate(paths.editResult);
          },
        },
        {
          label: "Home",
          icon: <Home sx={{ fontSize: 18 }} />,
          onClick: () => {
            setOpenAttendanceDrawer(false);
            navigate(paths.homeMenu);
          },
        },
        {
          onClick: () => {
            if (gradesSectionsList?.length === 1) {
              navigate(paths.takeClassSectionAttendance(gradesSectionsList[0].id));
            } else {
              setOpenAttendanceDrawer(true);
            }
          },
          label: "Take Attendance",
          icon: <Countertops sx={{ fontSize: 18 }} />,
        },
        {
          onClick: () => {
            setOpenAttendanceDrawer(false);
            navigate(paths.attendanceReportByFeatures);
          },
          label: "Attendance Report",
          icon: <AnalyticsOutlinedIcon sx={{ fontSize: 18 }} />,
        },
      ];
    } else if (user.role === "parent") {
      tempBottomNavigationItems = [
        ...tempBottomNavigationItems,
        {
          label: parentSelectedStudent?.first_name,
          icon: (
            <Avatar src={parentSelectedStudent?.profile_picture} sx={{ width: 20, height: 20 }} />
          ),
          onClick: () => {
            user?.details?.students?.length === 1
              ? navigate(paths.studentProfileView(user?.details?.students[0]?.id))
              : setOpenStudentDrawer((prevOpen) => !prevOpen);
          },
        },
        {
          label: "Fees",
          icon: <ClassOutlined sx={{ fontSize: 18 }} />,
          onClick: () => {
            setOpenStudentDrawer(false);
            navigate(paths.studentFee);
          },
        },
        {
          label: "Home",
          icon: <Home sx={{ fontSize: 18 }} />,

          onClick: () => {
            setOpenStudentDrawer(false);
            navigate(paths.parentDashBoard);
          },
        },
        {
          label: "Attendance",
          icon: <Countertops sx={{ fontSize: 18 }} />,
          onClick: () => {
            setOpenStudentDrawer(false);
            navigate(paths.studentAttandance);
          },
        },
        {
          label: "Notifications",
          icon: (
            <IconButton sx={{ padding: 0 }} onClick={() => navigate(paths.parentNotifications)}>
              <Badge badgeContent={unreadNotificationsCount} color="error">
                <NotificationsIcon sx={{ fontSize: 18 }} />
              </Badge>
            </IconButton>
          ),
          onClick: () => {
            setOpenStudentDrawer(false);
            setUnreadNotificationsCount(0);
            navigate(paths.parentNotifications);
          },
        },
      ];
    }
    setBottomNavigationItems(tempBottomNavigationItems);
  }, [user, parentSelectedStudent]);

  return (
    !!bottomNavigationItems.length && (
      <Box>
        <CssBaseline />
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1300,
          }}
        >
          <BottomNavigation
            showLabels
            value={selectedNavItem}
            onChange={(event, newValue) => {
              setSelectedNavItem(newValue);
            }}
          >
            {bottomNavigationItems.map((item, index) => (
              <BottomNavigationAction
                key={index}
                showLabel
                {...item}
                label={
                  <Typography sx={{ fontSize: "0.8em" }} variant="body2">
                    {item.label}
                  </Typography>
                }
                sx={{
                  px: 1,
                  minWidth: "50px",
                }}
              />
            ))}
          </BottomNavigation>
        </Paper>

        <Drawer
          anchor={"bottom"}
          open={openAttendanceDrawer}
          onClose={() => setOpenAttendanceDrawer(false)}
        >
          <Box
            sx={{ width: "auto" }}
            onClick={() => setOpenAttendanceDrawer(false)}
            onKeyDown={() => setOpenAttendanceDrawer(false)}
          >
            <List>
              {gradesSectionsList.length &&
                gradesSectionsList?.map((gradeSection, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => navigate(paths.takeClassSectionAttendance(gradeSection.id))}
                    >
                      <ListItemIcon>
                        <ClassSharp />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${gradeIndexToLabel[gradeSection?.grade__level]} ${
                          gradeSection?.title
                        }`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ClassSharp />
                  </ListItemIcon>
                  <ListItemText primary={"Hidden Item"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Drawer
          anchor={"bottom"}
          open={openStudentDrawer}
          onClose={() => setOpenStudentDrawer(false)}
        >
          <Box
            sx={{ width: "auto" }}
            onClick={() => setOpenStudentDrawer(false)}
            onKeyDown={() => setOpenStudentDrawer(false)}
          >
            <List>
              {user?.details?.students?.length &&
                user?.details?.students?.map((student) => (
                  <ListItem key={student.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(paths.studentProfileView(student.id));
                        changeParentStudent(student);
                        setOpenStudentDrawer(false);
                      }}
                    >
                      <ListItemIcon>
                        <Avatar src={student?.profile_picture} />
                      </ListItemIcon>
                      <ListItemText primary={`${student?.first_name} ${student?.last_name}`} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ClassSharp />
                </ListItemIcon>
                <ListItemText primary={"Hidden Item"} />
              </ListItemButton>
            </ListItem>
          </Box>
        </Drawer>
      </Box>
    )
  );
}
