import { appConfig } from "src/config";
import {
  reqAnonGet,
  reqAnonPost,
  reqGet,
  reqPatch,
  reqPost,
  getAuthHeaders,
  reqDelete,
} from "../utils/requests";

const hostname = window.location.hostname;
const tenantHostPrefix = hostname.split(".")[0];
const apiBaseUrl = `${appConfig.baseApiUrl}/${tenantHostPrefix}/api/v1`;

const apiCalls = {
  login: async (data) => await reqAnonPost(`${apiBaseUrl}/sign-in/`, data),
  signup: async (data) => await reqAnonPost(`${apiBaseUrl}/sign-up/`, data),
  thisTenant: async () => await reqAnonGet(`${apiBaseUrl}/tenants/current/`),
  currentUser: async () => await reqGet(`${apiBaseUrl}/users/current/`),
  verifyPassword: async (data) => await reqPost(`${apiBaseUrl}/users/verify-password/`, data),
  updatePassword: async (data) => await reqPost(`${apiBaseUrl}/users/update-password/`, data),
  resetPassword: async (data) => await reqAnonPost(`${apiBaseUrl}/users/reset-password/`, data),
  sendOtp: async (data) => await reqAnonPost(`${apiBaseUrl}/otp-verifications/send-otp/`, data),
  verifyOtp: async (data) => await reqAnonPost(`${apiBaseUrl}/otp-verifications/verify-otp/`, data),
  getDashboardStats: async (params) => await reqGet(`${apiBaseUrl}/dashboard-stats/`, params),
  getUsers: async (params) => await reqGet(`${apiBaseUrl}/users/`, params),
  getNonPagedUsers: async (params) => await reqGet(`${apiBaseUrl}/users/no-page`, params),
  createUsers: async (data) => await reqPost(`${apiBaseUrl}/users/`, data),
  getTheme: async () => await reqGet(`${apiBaseUrl}/users/get-theme/`),
  deleteTheme: async () => await reqDelete(`${apiBaseUrl}/users/remove-theme/`),
  updateTheme: async (data) => await reqPost(`${apiBaseUrl}/users/update-theme/`, data),
  getStudents: async (params) => await reqGet(`${apiBaseUrl}/students/`, params),
  deleteStudent: async (id) => await reqDelete(`${apiBaseUrl}/students/${id}/`),
  getStudentDetails: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/`),
  getStudentDetailedObject: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/details/`),
  getStudentDraft: async (id) => await reqGet(`${apiBaseUrl}/draft-student/${id}/`),
  getStudentDrafts: async (params) => await reqAnonGet(`${apiBaseUrl}/draft-student/`, params),
  addStudentDraft: async (data) => await reqAnonPost(`${apiBaseUrl}/draft-student/`, data),
  updateStudentDraft: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/draft-student/${id}/`, data),
  getStudentData: async (params) =>
    await reqGet(`${apiBaseUrl}/students/get-students-data/`, params),
  getStudentFeeDetails: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/fee/`),
  updateStudentFee: async (id, data) => await reqPost(`${apiBaseUrl}/students/${id}/fee/`, data),
  createStudentCustomFee: async (data) =>
    await reqPost(`${apiBaseUrl}/student-chargeable-fee/`, data),
  getStudentCustomFees: async (params) =>
    await reqGet(`${apiBaseUrl}/student-chargeable-fee/`, params),
  updateStudentCustomFee: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-chargeable-fee/${id}/`, data),
  deleteStudentCustomFee: async (id) =>
    await reqDelete(`${apiBaseUrl}/student-chargeable-fee/${id}/`),
  createFeeTag: async (data) => await reqPost(`${apiBaseUrl}/fee-tags/`, data),
  getFeeTags: async (params) => await reqGet(`${apiBaseUrl}/fee-tags/`, params),
  getFeeTag: async (id) => await reqGet(`${apiBaseUrl}/fee-tags/${id}`),
  updateFeeTag: async (id, data) => await reqPatch(`${apiBaseUrl}/fee-tags/${id}/`, data),
  deleteFeeTag: async (id) => await reqDelete(`${apiBaseUrl}/fee-tags/${id}/`),
  getStudentCustomFee: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/applicable-fees/`),
  getStudentsImportSummaries: async (params) =>
    await reqGet(`${apiBaseUrl}/students-import-summary/`, params),
  getStudentsImportSummaryDetail: async (id) =>
    await reqGet(`${apiBaseUrl}/students-import-summary/${id}/`),
  getRecentlyAddedStudents: async (params) =>
    await reqGet(`${apiBaseUrl}/students/recently-added/`, params),
  createStudents: async (data) => await reqPost(`${apiBaseUrl}/students/`, data),
  getStudentsStats: async (params) => await reqGet(`${apiBaseUrl}/students/stats/`, params),
  generateIdCards: async (params) =>
    await reqGet(`${apiBaseUrl}/students/generate-id-cards`, params),
  getGrades: async () => await reqGet(`${apiBaseUrl}/grades/`),
  getGrade: async (id) => await reqGet(`${apiBaseUrl}/grades/${id}/`),
  createGrade: async (data) => await reqPost(`${apiBaseUrl}/grades/`, data),
  updateGrade: async (id, data) => await reqPatch(`${apiBaseUrl}/grades/${id}/`, data),
  getGradesQuickList: async () => await reqGet(`${apiBaseUrl}/grades/quick-list/`),
  getGradesSection: async (id) => await reqGet(`${apiBaseUrl}/grade-sections/${id}/`),
  generateRollNumber: async (id) =>
    await reqPost(`${apiBaseUrl}/grades/${id}/initialize-roll-number/`),
  updateGradesSection: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/grade-sections/${id}/`, data),
  saveGradesSectionAttendance: async (id, data) =>
    await reqPost(`${apiBaseUrl}/grade-sections/${id}/save-attendance/`, data),
  getGradesSectionAllStudents: async (id) =>
    await reqGet(`${apiBaseUrl}/grade-sections/${id}/all-students/`),
  generateRasa: async (id, params) =>
    await reqGet(`${apiBaseUrl}/grade-sections/${id}/generate-rasa/`, params),
  getGradesSectionsList: async () => await reqGet(`${apiBaseUrl}/grades/sections-list/`),
  getAttendance: async (params) => await reqGet(`${apiBaseUrl}/attendance/`, params),
  getAttandanceByDate: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/all-attendance-by-date/`, params),
  getDayToDayAttandance: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/analytics-grade-per-day`, params),
  getAttendanceStats: async (params) => await reqGet(`${apiBaseUrl}/attendance/stats/`, params),
  attendanceReport: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/grade-analytics/`, params),
  getDateRangeAttendance: async (params) =>
    await reqGet(`${apiBaseUrl}/attendance/in-date-range/`, params),
  getGradesSectionAttendances: async (params) => await reqGet(`${apiBaseUrl}/attendance/`, params),
  getGradesSectionAttendanceDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/attendance/${id}/`, params),
  updatedStudentMarkedAttendance: async (id, data) =>
    await reqPost(`${apiBaseUrl}/attendance/${id}/update/`, data),
  uploadAttendanceData: async (data) =>
    await reqPost(`${apiBaseUrl}/attendance/upload-student-attendance/`, data),
  getTransactions: async (params) => await reqGet(`${apiBaseUrl}/transactions/`, params),
  getTransactionsStats: async (params) => await reqGet(`${apiBaseUrl}/transactions/stats/`, params),
  getTransactionDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/transactions/${id}/`, params),
  searchGlobally: async (params) => await reqGet(`${apiBaseUrl}/global-search/`, params),
  uploadStudents: async (data) =>
    await reqPost(`${apiBaseUrl}/students/import/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),
  uploadUsers: async (data) =>
    await reqPost(`${apiBaseUrl}/users/import/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),
  getUserDetails: async (id) => await reqGet(`${apiBaseUrl}/users/${id}/details`),
  updatePermissions: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/users/${id}/update-permissions/`, data),

  updateUserDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/users/${id}/update-details/`, data),
  addTransaction: async (data) => await reqPost(`${apiBaseUrl}/transactions/`, data),
  addFinanceAccount: async (data) => await reqPost(`${apiBaseUrl}/finance-accounts/`, data),
  getAllFinanceAccounts: async (params) => await reqGet(`${apiBaseUrl}/finance-accounts/`, params),
  getFinanceAccount: async (id, params) =>
    await reqGet(`${apiBaseUrl}/finance-accounts/${id}/`, params),
  updateFinanceAccount: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/finance-accounts/${id}/`, data),
  addExamination: async (data) => await reqPost(`${apiBaseUrl}/examinations/`, data),
  updateExamination: async (id, data) => await reqPatch(`${apiBaseUrl}/examinations/${id}/`, data),
  getExaminations: async () => await reqGet(`${apiBaseUrl}/examinations/`),
  deleteExamination: async (id) => await reqDelete(`${apiBaseUrl}/examinations/${id}/`),
  getExaminationDetails: async (id, params) =>
    await reqGet(`${apiBaseUrl}/examinations/${id}/`, params),
  getSubjects: async (params) => await reqGet(`${apiBaseUrl}/subjects/`, params),
  addSubjects: async (data) => await reqPost(`${apiBaseUrl}/subjects/`, data),
  getSubjectDetails: async (id) => await reqGet(`${apiBaseUrl}/subjects/${id}/`),
  updateSubjects: async (id, data) => await reqPatch(`${apiBaseUrl}/subjects/${id}/`, data),

  deleteSubject: async (subject_id) => {
    await reqDelete(`${apiBaseUrl}/subjects/${subject_id}/`);
  },

  addExamSchedule: async (data) => await reqPost(`${apiBaseUrl}/exam-schedules/`, data),
  getExamSchedule: async (params) => await reqGet(`${apiBaseUrl}/exam-schedules/`, params),
  getExamScheduleDetails: async (id) => await reqGet(`${apiBaseUrl}/exam-schedules/${id}/`),
  updateExamSchedule: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/exam-schedules/${id}/`, data),
  deleteExamSchedule: async (id) => await reqDelete(`${apiBaseUrl}/exam-schedules/${id}/`),
  uploadExamResults: async (id, data) =>
    await reqPost(`${apiBaseUrl}/examinations/${id}/update-results/`, data, {
      ...getAuthHeaders(),
      "Content-Type": "multipart/form-data",
    }),

  getDraftResults: async (params) => await reqGet(`${apiBaseUrl}/draft-results/`, params),

  deleteDraftResult: async (id) => await reqDelete(`${apiBaseUrl}/draft-results/${id}/`),

  getDraftResultsDetails: async (id) => await reqGet(`${apiBaseUrl}/draft-results/${id}`),

  getStudentUsingEnrollmentID: async (params) => await reqGet(`${apiBaseUrl}/students/`, params),

  getAllStudents: async () => await reqGet(`${apiBaseUrl}/students/all/`),

  getExamScheduleUsingGradeSection: async (params) =>
    await reqGet(`${apiBaseUrl}/exam-schedules/`, params),

  verifyStudentResult: async (examId, data) =>
    await reqPost(`${apiBaseUrl}/examinations/${examId}/approve-student-draft/`, data),

  getResults: async (params) => await reqGet(`${apiBaseUrl}/student-results/`, params),
  getResultsDetails: async (id) => await reqGet(`${apiBaseUrl}/student-results/${id}/`),
  updateResultsDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-results/${id}/`, data),

  printResults: async (id) => await reqGet(`${apiBaseUrl}/student-results/${id}/print-result/`),
  syncStudentResult: async (id) =>
    await reqPost(`${apiBaseUrl}/examinations/${id}/sync-missing-students-results/`),
  printAllResults: async (ids) =>
    await reqPost(`${apiBaseUrl}/student-results/print-results/`, ids),

  getSalaryDetails: async (id) => await reqGet(`${apiBaseUrl}/users/${id}/salary/`),

  paySalary: async (id, data) => await reqPost(`${apiBaseUrl}/users/${id}/salary/`, data),

  createTenant: async (data) => await reqPost(`${apiBaseUrl}/tenants/create-tenant/`, data),
  getTenants: async () => await reqGet(`${apiBaseUrl}/tenants/`),
  createOrUpdateFCMToken: async (data) =>
    await reqPost(`${apiBaseUrl}/push-notifications/token/`, data),
  getFCMToken: async (params) => await reqGet(`${apiBaseUrl}/push-notifications/token/`, params),
  deleteFCMToken: async (data) =>
    await reqPost(`${apiBaseUrl}/push-notifications/delete-token/`, data),
  updateExamFee: async (id, data) => await reqPost(`${apiBaseUrl}/students/${id}/exam-fee/`, data),

  getExamFee: async (id, data) => await reqGet(`${apiBaseUrl}/students/${id}/exam-fee/`, data),

  updateAdmissionFee: async (id, data) =>
    await reqPost(`${apiBaseUrl}/students/${id}/admission-fee/`, data),

  getAdmissionFee: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/admission-fee/`),

  updateCustomFees: async (id, data) =>
    await reqPost(`${apiBaseUrl}/students/${id}/chargeable-fees/`, data),

  exportStudents: async (params) => await reqGet(`${apiBaseUrl}/students/export-csv/`, params),

  BulkUpdateStudents: async (data) => await reqPatch(`${apiBaseUrl}/students/bulk-update/`, data),

  updateStudents: async (id, data) => await reqPatch(`${apiBaseUrl}/students/${id}/`, data),

  updateStudentDetails: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/student-details/${id}/`, data),

  getUnPaidExams: async (id) => await reqGet(`${apiBaseUrl}/students/${id}/get-unpaid-exam-fees/`),
  getUnPaidTutionFeeStudents: async (id) =>
    await reqGet(`${apiBaseUrl}/students/${id}/get-unpaid-tution-fees/`),

  getAllPaidFees: async (id, params) =>
    await reqGet(`${apiBaseUrl}/transactions/${id}/student-fees/`, params),

  getAllStudentFee: async (params) =>
    await reqGet(`${apiBaseUrl}/transactions/all-student-fees/`, params),

  getAllStudentsFeeDetails: async () =>
    await reqGet(`${apiBaseUrl}/students/get-all-fees-details/`),

  getFinanceChartData: async () => await reqGet(`${apiBaseUrl}/transactions/get-chart-data/`),

  getSchoolSettings: async () => await reqGet(`${apiBaseUrl}/school-settings/`),
  getSchoolSetting: async (id) => await reqGet(`${apiBaseUrl}/school-settings/${id}/`),

  updateSchoolSettings: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/school-settings/${id}/`, data),

  updateTenant: async (id, data) => await reqPatch(`${apiBaseUrl}/tenants/${id}/`, data),

  getAssoassociatedPersonEntity: async () =>
    await reqGet(`${apiBaseUrl}/transactions/get-all-associated-person-or-entity/`),

  getLiveResultExam: async () => await reqGet(`${apiBaseUrl}/examinations/get-live-result-exam/`),
  findLiveResultStudent: async (params) =>
    await reqGet(`${apiBaseUrl}/examinations/find-public-results/`, params),
  getLiveResult: async (params) =>
    await reqGet(`${apiBaseUrl}/examinations/get-live-result-exam/`, params),

  getAnnouncements: async () => await reqGet(`${apiBaseUrl}/announcements/`),
  getAnnouncement: async (id) => await reqGet(`${apiBaseUrl}/announcements/${id}/`),

  addAnnouncement: async (data) => await reqPost(`${apiBaseUrl}/announcements/`, data),
  getPublicAnnouncements: async (data) =>
    await reqGet(`${apiBaseUrl}/announcements/get-recent-public-announcements/`),
  deleteAnnouncement: async (announcement_id) =>
    await reqDelete(`${apiBaseUrl}/announcements/${announcement_id}/delete/`),

  updateAnnouncement: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/announcements/${id}/`, data),

  addAdmission: async (data) => await reqPost(`${apiBaseUrl}/admission-query/`, data),
  getAdmissionDetails: async () => await reqGet(`${apiBaseUrl}/admission-query/`),

  getPosts: async () => await reqGet(`${apiBaseUrl}/public-posts/`),
  getPost: async (id) => await reqGet(`${apiBaseUrl}/public-posts/${id}/`),
  addPost: async (data) => await reqPost(`${apiBaseUrl}/public-posts/`, data),
  updatePost: async (id, data) => await reqPatch(`${apiBaseUrl}/public-posts/${id}/`, data),
  getPublicPosts: async () => await reqGet(`${apiBaseUrl}/public-post/`),
  getPublicPost: async (id) => await reqGet(`${apiBaseUrl}/public-post/${id}/`),
  deletePost: async (id) => await reqDelete(`${apiBaseUrl}/public-posts/${id}/delete/`),

  addStorageFile: async (data) => await reqPost(`${apiBaseUrl}/storage-files/upload/`, data),
  deleteStorageFile: async (id) =>
    await reqDelete(`${apiBaseUrl}/storage-files/${id}/delete_file/`),
  getStorageFiles: async () => await reqGet(`${apiBaseUrl}/storage-files/`),
  getStorageFolders: async () => await reqGet(`${apiBaseUrl}/storage-folders/`),

  getPublicWebsites: async () => await reqGet(`${apiBaseUrl}/public-websites/`),
  getPublicWebsite: async () => await reqGet(`${apiBaseUrl}/public-website/`),
  updatePublicWebsite: async (id, data) =>
    await reqPatch(`${apiBaseUrl}/public-websites/${id}/`, data),
  addPublicWebsite: async (data) => await reqPost(`${apiBaseUrl}/public-websites/`, data),
  getNotifications: async (params = {}) => await reqGet(`${apiBaseUrl}/notifications/`, params),
  updateNotificationsReadTime: async (data = {}) =>
    await reqPost(`${apiBaseUrl}/notifications/update-my-checked-at/`, data),
  testUnitFeature: async (featureName, ids) =>
    await reqPost(`${apiBaseUrl}/test-unit-feature/${featureName}/`, ids),
  getPaymentReceipt: async (id) => await reqGet(`${apiBaseUrl}/transactions/${id}/get-receipt/`),
  getEventList: async () => await reqGet(`${apiBaseUrl}/calendar/list-upcoming-events/`),
  getEvents: async (params) => await reqGet(`${apiBaseUrl}/calendar/`, params),
  getEvent: async (id) => await reqGet(`${apiBaseUrl}/calendar/${id}/`),
  updateEvent: async (id, data) => await reqPatch(`${apiBaseUrl}/calendar/${id}/`, data),
  deleteEvent: async (id) => await reqDelete(`${apiBaseUrl}/calendar/${id}/`),
  createEvent: async (data) => await reqPost(`${apiBaseUrl}/calendar/`, data),
  getAlertMessages: async (params) => await reqGet(`${apiBaseUrl}/action-item/`, params),
};

export default apiCalls;
